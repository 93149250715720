<template>
  <div>
    <v-container>
      <h1 style="text-align: left;margin-bottom: 20px">{{ $t('ourProduct') }}</h1>
      <div v-if="!$vuetify.breakpoint.mobile"  class="animate__animated animate__delay-1s  animate__slideInUp">
        <div class="ten_news_item" v-for="(d,i) in dataList" :key="i" style="cursor: pointer" @click="gotoDetail(d)">
          <div style="text-align: left">
            <span class="ten_tagline">{{ d.createdAt | formatDate }}</span>
            <h1 v-html="cutText(getTranslate(d.title), 50)"></h1>
            <p v-html="cutText(getTranslate(d.body), 500)"></p>
          </div>
          <div>
            <v-img :src="d.url" style="width: 480px;height: 320px" contain></v-img>
          </div>
        </div>
      </div>
      <div v-if="$vuetify.breakpoint.mobile"  class="animate__animated animate__delay-1s  animate__slideInUp">
        <div class="bBottom" v-for="(d,i) in dataList" :key="i" style="cursor: pointer" @click="gotoDetail(d)">
          <div style="text-align: left">
            <span class="ten_tagline">{{ d.createdAt | formatDate }}</span>
            <h1 v-html="cutText(getTranslate(d.title), 50)"></h1>
            <p v-html="cutText(getTranslate(d.body), 500)"></p>
          </div>
          <div>
            <v-img :src="d.url" style="width: 480px;height: 320px" contain></v-img>
          </div>
        </div>
      </div>

    <v-row style="margin-top: 40px">
      <v-col cols="12" sm="12" md="12" style="overflow-x: auto;display: block;white-space: nowrap;"  class="animate__animated animate__delay-3s  animate__slideInUp">
        <table class="tablePadding table-bordered table-col" >
          <caption><h1 style="color: blue">{{$t('productPrice')}}</h1></caption>
          <thead style="margin-top: 5px">
          <tr>
            <th>{{ $t('no') }}</th>
            <th style="text-align: left !important;">{{ $t('productName') }}</th>
            <th v-for="d in dataHeaderReport" :key="d">{{ d | formatDate }}</th>
          </tr>
          </thead>

          <tbody class="tableCollapse" style="margin-bottom: 5px;" v-html="dataHtml">
          </tbody>

        </table>

      </v-col>
    </v-row>

    </v-container>
  </div>
</template>

<script>
import MyMixin from "@/mixins/mixin"
import {Constants} from "@/libs/constant";
import axios from "axios";

export default {
  name: "OurProduct",
  mixins: [MyMixin],
  data() {
    return {
      dataList: [],
      dataHtml: "",
      dataHeaderReport: []
    }
  },
  methods: {
    cutText(d, length) {
      if (!d) return "";
      return d.substr(0, length);
    },
    gotoDetail(doc) {
      this.$router.push({name: 'NewsEventsDetail', params: {doc: doc}});
    },
    fetchProduct() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getProduct;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.dataList = res.data.data;
              }
            },
            error => {
              reject(error);

            }
        );
      });
    },
    fetchProductReport() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getProductReport;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.dataHtml = res.data.data.dataHtml;
                vm.dataHeaderReport = res.data.data.monthList;
              }
            },
            error => {
              reject(error);

            }
        );
      });
    }
  },
  created() {
    let vm = this;
    vm.fetchProduct();
    vm.fetchProductReport();
  }
}
</script>


<style scoped>
.ten_news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0px;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;
}
</style>